import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportDirective } from './in-viewport.directive';
import { DragAndDropDirective } from './drag-and-drop.directive';

@NgModule({
  declarations: [
    InViewportDirective,
    DragAndDropDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InViewportDirective,
    DragAndDropDirective
  ]
})
export class SharedDirectivesModule { }
