import {
  Directive,
  ElementRef,
  HostBinding,
  EventEmitter,
  Output,
  OnDestroy,
  AfterViewInit,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { WINDOW } from '../../universal/window/window.service';

@Directive({
  selector: '[rtInViewport]',
  exportAs: 'rtInViewport'
})
export class InViewportDirective implements AfterViewInit, OnDestroy, OnInit {
  private inViewport: boolean;
  private hasIntersectionObserver: boolean;

  @Input()
  inViewportOptions: IntersectionObserverInit;

  @Output()
  inViewportChange = new EventEmitter<boolean>();
  observer: IntersectionObserver;

  @HostBinding('class.rt-viewport--in')
  get isInViewport(): boolean {
    return this.inViewport;
  }

  @HostBinding('class.rt-viewport--out')
  get isNotInViewport(): boolean {
    return !this.inViewport;
  }

  constructor(private el: ElementRef, @Inject(WINDOW) private window: Window) {
    this.hasIntersectionObserver = this.intersectionObserverFeatureDetection();
  }

  ngOnInit() {
    if (!this.hasIntersectionObserver) {
      this.inViewport = true;
      this.inViewportChange.emit(this.inViewport);
    }
  }

  ngAfterViewInit() {
    if (this.hasIntersectionObserver) {
      const IntersectionObserver = this.window['IntersectionObserver'];
      this.observer = new IntersectionObserver(
        this.intersectionObserverCallback.bind(this),
        this.inViewportOptions
      );

      this.observer.observe(this.el.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.el.nativeElement);
    }
  }

  intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      // Important check for Firefox
      if (entry.intersectionRatio < +this.inViewportOptions.threshold) {
        this.inViewport = false;
      } else {
        if (this.inViewport === entry.isIntersecting) return;
        this.inViewport = entry.isIntersecting;
      }
      this.inViewportChange.emit(this.inViewport);
    });
  }

  private intersectionObserverFeatureDetection() {
    if ('IntersectionObserver' in this.window && 'IntersectionObserverEntry' in this.window) {
      if (!('isIntersecting' in this.window['IntersectionObserverEntry']['prototype'])) {
        Object.defineProperty(
          this.window['IntersectionObserverEntry']['prototype'],
          'isIntersecting',
          {
            get () {
              return this.intersectionRatio > 0;
            }
          }
        );
      }
      return true;
    }
    return false;
  }
}
